<template>
    <div id="map" class="map-wrapper">
        <!--In the following div the HERE Map will render-->
        <div id="mapContainer" ref="hereMap" style="height:100%;" class="shadow-lg">
        </div>
    </div>
</template>

<script>
        export default {
                name: "HereMap",
                props: {},
                data() {
                        return {
                                platform: null,
                                apikey: "q5ijC7m2SlXXv4aybg8ZO9dYBrq-ema37efhgP7DHZo"
                                // You can get the API KEY from developer.here.com
                        };
                },
                async mounted() {
                        // Initialize the platform object:
                        const platform = new window.H.service.Platform({
                                apikey: this.apikey
                        });
                        this.platform = platform;
                        this.initializeHereMap();
                },
                methods: {
                        initializeHereMap() { // rendering map

                                const mapContainer = this.$refs.hereMap;
                                const H = window.H;
                                // Obtain the default map types from the platform object
                                var maptypes = this.platform.createDefaultLayers();
                                // Instantiate (and display) a map object:
                                var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
                                        zoom: 14,
                                        center: {lat: 43.30266392383486, lng: 21.8669211111111}
                                        // center object { lat: 40.730610, lng: -73.935242 }
                                });
                                var parisMarker = new H.map.Marker({lat: 43.30255192383486, lng: 21.8669211111111});
                                map.addObject(parisMarker);
                                map.addEventListener('tap', event => {
                                        if (event.target instanceof H.map.Marker) {
                                                // Some action. Typically, you want to use the data that you may have referenced
                                                // in the marker creation code, or get the coordinates. Here we log the data
                                                window.open('https://www.google.com/maps/place/Mik-Komerc/@43.3025195,21.8669572,15z/data=!4m2!3m1!1s0x0:0x5e967c3626aae02f?sa=X&ved=2ahUKEwjVsO2tyOD1AhXYGLkGHfHiAGcQ_BJ6BAg1EAU&hl=sr', '_blank');
                                        }
                                });
                                addEventListener("resize", () => map.getViewPort().resize());

                                // add behavior control
                                new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

                                // add UI
                                H.ui.UI.createDefault(map, maptypes);
                                // End rendering the initial map
                        }
                }
        };
</script>

<style scoped>
    .map-wrapper {
        height: 540px;
        margin-top: 35px;
        margin-bottom: 30px;
    }
</style>
