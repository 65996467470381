<template>
  <IndexPage msg="Welcome to Your Vue.js App"/>
</template>

<script>
import IndexPage from './components/IndexPage.vue'

export default {
  name: 'App',
  components: {
    IndexPage
  }
}
</script>

<style>
</style>
